/* App.css */

html,
body,
#root {
  height: 100%;
  font-family: 'Raleway', Tahoma, Geneva, Verdana, sans-serif;
  scroll-behavior: smooth;
  min-width: 400px;
}

body::-webkit-scrollbar {
  width: 0; /* Remove scrollbar width in Webkit browsers (Chrome, Safari) */
}

/* Basic reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h2 {
  margin-bottom: 0;
  font-size: 100px;
  font-weight: 400;
}

a {
  text-decoration:none;
  color: #ffffff
}

/* General styles */
body {
  color: #333;
  margin: 0;
  padding: 0;
  font-family: 'Raleway', -apple-system, 'SF Pro', 'Helvetica Neue', Arial, sans-serif;
  min-height: 400px;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.first-section {
  background: #000000;
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.second-section {
  padding: 80px 50px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #ffffff;
  padding-bottom: 100px;
}

.text-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.navbar-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h1 {
  padding: 0;
  line-height: 0.8;
  font-size: 10rem;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
  white-space: pre-line;
}

h1:nth-child(1) {
 justify-self: start;
 text-align: left;
}

h1:nth-child(2) {
  text-align: right;
  justify-self: end;
}

.navbar {
  color: #ffffff;
  display: flex;
  gap: 60px;
}


.navbar-twitter {
  color: #ffffff;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.footer-twitter {
  display: none;
  padding-top: 10px;
}

/* Main content styles */
.main-content {
  flex: 1; /* Take remaining space in the middle */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  margin:auto
}

/* Footer styles */
.footer {
  text-align: center;
  padding: 30px 0;
  font-size: 12px;
  color: #888;
}

.process-items {
  align-items: flex-start;
  color: #000000;
  display: flex;
  flex-direction: row;
  text-align: center;
  max-width: 600px; /* Maximum width for the grid container */
  margin: 0 auto;
  padding-top: 120px;
  gap: 40px;
}

.process-item {
  max-width: 500px;
  margin: 0 auto;
  gap: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  flex: 1;
}

@media only screen and (max-width: 800px) {
  .featured-section {
    flex-direction: column;
    align-items: center;
  }
  .featured-item {
    margin-bottom: 30px;
    min-width: 250px;
  }

  .text-container {
    grid-template-columns: 1fr;
  }

  h1 {
    text-align: center;
    font-size: 5rem;
  }

  h2 {
    font-size: 50px;
  }

  .second-section {
    padding: 40px 50px;
  }

  .navbar-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .main-content {
    grid-template-columns: 1fr; /* Switch to a single column under 800px */
  }

  .navbar-twitter {
    display: none !important;
  }

  .footer-twitter {
    display: block !important;
  }

  .process-items {
    flex-direction: column;
    gap: 50px;
    padding-top: 60px;
  }
}
